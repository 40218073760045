
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { environment, } from '../environments/environment.prod';
import { EventTrackingService } from './services/google/event-tracking.service';
import { PageTitleService } from './services/title/page-title.service';
import { filter, map } from 'rxjs/operators';
declare let gtag: Function;
import { LocalStorageService } from './core/services/local-stroage.service'
import { UserTypeConstants, authKey } from "src/app/core/constants/userType.constant";
import * as e from 'express';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  public spinkit = Spinkit;
  skChasingDots = 'sk-chasing-dots'
  skCubeGrid = 'sk-cube-grid'
  skDoubleBounce = 'sk-double-bounce'
  skRotatingPlane = 'sk-rotationg-plane'
  skSpinnerPulse = 'sk-spinner-pulse'
  skThreeBounce = 'sk-three-bounce'
  skWanderingCubes = 'sk-wandering-cubes'
  skWave = 'sk-wave'
  activeComponent: string;

  constructor(
    private router: Router,
    private googleEventTracking: EventTrackingService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private meta: Meta,
  ) {

    // if (window.performance.navigation.type == 1) {
    //   return;
    // }else{
    //   localStorage.removeItem('slugName');
    //   // window.onbeforeunload = () => {
    //   //   localStorage.removeItem('slugName');
    //   // }
    // }
    
    window.onbeforeunload = () => {
      // localStorage.removeItem('slugName');
    }
    // location.onPopState(() => {
    //   console.log(`pressed back in add!!!!!`);
    //   //this.router.navigateByUrl(‘/multicomponent’);
    //   //history.forward();
    //   });
    if (localStorage.getItem('debug') == 'false') {
      window.console.log = function () { };   // disable any console.log debugging statements in production mode
      window.console.error = function () { };
    } else {

    }
    if (!localStorage.getItem('isAuthenticated')) {

      localStorage.setItem('sessionId', authKey.defaultAuthKey);
    }

    // if(environment.production ) {
    // } else {
    //   // window.console.log = function () { };   // disable any console.log debugging statements in production mode
    //   window.console.error = function () { };
    // }

  }

  ngOnInit() {
    const appTitle = this.pageTitleService.getTitle();
    this.setPageTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          this.activeComponent = child.snapshot.data['componentName'];
          console.log(this.activeComponent);

          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        console.log(ttl);
        //this.pageTitleService.setTitle(ttl);
      });



    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationEnd) {
        this.googleEventTracking.routeTrack(evt.urlAfterRedirects);
      }


      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.setPageTitle();
     

    });
  }

  setPageTitle() {
    if (this.router.url.includes('subscribe/choseplan')) {
      this.pageTitleService.setTitle(`Subscribe to Platform8 - Choose Your Plan Today`);
      this.meta.updateTag({ name: 'description', content: `Choose the perfect subscription plan on Platform8 and enjoy unlimited access to Bengali movies, TV shows, web series, music, and more. Start streaming now!`});
      this.meta.updateTag({ name: 'keywords', content: `Platform8 subscription plans, Subscribe to Platform8, Unlimited Bengali content streaming, Platform8 OTT plans, Stream Bengali movies and TV shows` });
      return;
    }
    else if (this.router.url.includes('viewall')) {
      this.pageTitleService.setTitle(`Explore Platform8's Latest Collection`);
      this.meta.updateTag({ name: 'description', content: `Discover the newest products and services offered by Platform8. Browse our comprehensive collection to find innovative solutions and offerings for your needs.`});
      this.meta.updateTag({ name: 'keywords', content: `Platform8 new collection, Latest offerings on Platform8, Platform8 innovative solutions, Platform8 products` });
      return;
    }

    const currentPage = this.router.url.split('/')[1];
    switch(currentPage) {
      case 'home':
        this.pageTitleService.setTitle(`Platform8 - Bengali Entertainment OTT Platform`);
        this.meta.updateTag({ name: 'description', content: `Explore Platform8 for a diverse collection of Bengali short films, TV series, and dramas. Enjoy family-friendly entertainment anytime, anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `Bengali OTT platform, Bengali short films, Bengali TV series, Bengali dramas online, Family-friendly Bengali entertainment, Watch Bengali movies online` });
      break;

      case 'series':
        this.pageTitleService.setTitle(`Platform8 Series - Watch Bengali Web Series Online`);
        this.meta.updateTag({ name: 'description', content: `Discover captivating Bengali web series on Platform8. Stream popular dramas, thrillers, and family shows anytime, exclusively on our OTT platform.`});
        this.meta.updateTag({ name: 'keywords', content: `Bengali web series, Watch Bengali web series online, Bengali drama series, Bengali thrillers online, Family Bengali shows` });
      break;

      case 'tv-shows':
        this.pageTitleService.setTitle(`Platform8 TV Shows - Bengali Entertainment Online`);
        this.meta.updateTag({ name: 'description', content: `Stream top Bengali TV shows on Platform8. Enjoy a variety of engaging dramas, comedies, and family entertainment, available anytime on our OTT platform.`});
        this.meta.updateTag({ name: 'keywords', content: `Bengali TV shows online, Stream Bengali TV shows, Watch Bengali comedies, Bengali drama TV shows, Family-friendly Bengali shows` });
      break;

      case 'movies':
        this.pageTitleService.setTitle(`Platform8 Movies - Stream Bengali Films Online`);
        this.meta.updateTag({ name: 'description', content: `Watch the best Bengali movies on Platform8. Enjoy a wide collection of classics, blockbusters, and exclusive films, anytime, anywhere on our OTT platform.`});
        this.meta.updateTag({ name: 'keywords', content: `Bengali films online, Watch Bengali movies, Stream Bengali blockbusters, Best Bengali movie collection, Classic Bengali films, Exclusive Bengali movies` });
      break;

      case 'music':
        this.pageTitleService.setTitle(`Platform8 Music - Stream Bengali Songs Online`);
        this.meta.updateTag({ name: 'description', content: `Enjoy soulful Bengali music on Platform8. Discover a rich collection of songs, including classics and modern hits, streaming anytime on our OTT platform.`});
        this.meta.updateTag({ name: 'keywords', content: `Stream Bengali songs, Bengali music online, Listen to Bengali hits, Bengali music collection, Classic Bengali songs, Modern Bengali music` });
      break;
      
      case 'police-filez':
        this.pageTitleService.setTitle(`Police Filez - Watch Crime Stories on Platform8`);
        this.meta.updateTag({ name: 'description', content: `Explore gripping crime stories on "Police Filez" exclusively on Platform8. Uncover thrilling mysteries and investigative dramas, streaming anytime.`});
        this.meta.updateTag({ name: 'keywords', content: `Watch crime stories online, Gripping crime dramas, Crime mysteries streaming, Investigative crime series, Police thriller shows` });
      break;

      case 'aboutus':
        this.pageTitleService.setTitle(`About Platform8 - Innovation in Streaming Solutions`);
        this.meta.updateTag({ name: 'description', content: `Discover Platform8’s commitment to transforming streaming experiences with cutting-edge technology. Learn about our mission, vision, and the team dedicated to delivering top-tier services.`});
        this.meta.updateTag({ name: 'keywords', content: `Platform8 streaming solutions, Innovation in streaming technology, Platform8 mission and vision, Transforming streaming experiences, Platform8 team and services` });
      break;

      case 'contactus':
        this.pageTitleService.setTitle(`Contact Platform8 - Get in Touch`);
        this.meta.updateTag({ name: 'description', content: `Reach out to Platform8 for inquiries, support, or feedback. Visit our contact page to connect with our team for assistance and information.`});
        this.meta.updateTag({ name: 'keywords', content: `Contact Platform8, Reach out to Platform8, Platform8 support, Platform8 customer service, Platform8 inquiries` });
      break;

      case 'terms':
        this.pageTitleService.setTitle(`Terms and Conditions - Platform8`);
        this.meta.updateTag({ name: 'description', content: `Review the terms and conditions of using Platform8's services. Learn about our policies on privacy, user agreements, and the guidelines for using our platform.`});
        this.meta.updateTag({ name: 'keywords', content: `Platform8 terms of service, Platform8 privacy policy, User agreements Platform8, Terms and conditions Platform8, Platform8 platform guidelines` });
      break;

      case 'policy':
        this.pageTitleService.setTitle(`Privacy Policy - Platform8`);
        this.meta.updateTag({ name: 'description', content: `Read Platform8’s privacy policy to understand how we protect your personal data and ensure a secure experience. Learn about data collection, use, and protection practices.`});
        this.meta.updateTag({ name: 'keywords', content: `Platform8 privacy policy, Protect personal data Platform8, Platform8 data collection` });
      break;

      default:
        this.pageTitleService.setTitle(`Platform8 - Bengali Entertainment OTT Platform`);
        this.meta.updateTag({ name: 'description', content: `Explore Platform8 for a diverse collection of Bengali short films, TV series, and dramas. Enjoy family-friendly entertainment anytime, anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `Bengali OTT platform, Bengali short films, Bengali TV series, Bengali dramas online, Family-friendly Bengali entertainment, Watch Bengali movies online` });
      break;
    }
  }

}
